import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Feedback,
  FeedbackCreateInput,
  FeedbackFilters,
  FeedbackIncludes,
  FeedbackSorts,
  FeedbackUpdateInput,
} from '../Types/Resources/Feedback';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IEnrollmentFeedbackProps
  extends HookParams<
    typeof FeedbackIncludes,
    typeof FeedbackFilters,
    typeof FeedbackSorts
  > {
  enrollment?: UUID;
}

export function useEnrollmentFeedback(
  { enrollment, ...queryParameters }: IEnrollmentFeedbackProps = {},
  settings?: IUseQueryOptions<DataDocument<Feedback>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.feedback',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/feedback`,
      invalidate: [
        'enrollment',
        'enrollments',
        'v1.enrollments',
        'v1.portals.courses',
        'courses',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Feedback, FeedbackCreateInput, FeedbackUpdateInput>(
    request,
    settings
  );
}
