import React from 'react';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import classNames from 'classnames/bind';
import { LtiToolProviderForm } from 'Forms/Integrations';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import styles from './IntegrationsModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  integrationId?: UUID;
};

/**
 * Modal to create or update an integration. The idea of the modal is to add multiple types of integrations.
 */
export const IntegrationsModal = ({ integrationId }: TProps) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();

  const action = integrationId ? 'update' : 'create';

  return (
    <SidebarModal
      size="medium"
      className={cx(styles.base)}
      closeModal={closeModal}
      closeable
    >
      <SidebarModal.Header className={cx(styles.header)}>
        <h2>{t(`modals.integrations.${action}.title`)}</h2>
      </SidebarModal.Header>

      <LtiToolProviderForm
        formId="integrations-form"
        ltiToolProviderId={integrationId}
        onSuccess={() => closeModal()}
      />
    </SidebarModal>
  );
};
