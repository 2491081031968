import React from 'react';
import { Enrollment } from '@brainstud/academy-api/Types/Resources/Enrollment';
import { Status } from '@brainstud/ui/Static/Status';
import classNames from 'classnames/bind';
import { useGetEnrollmentFeedback } from 'Hooks/useGetEnrollmentFeedback';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './FeedbackContent.module.css';

const cx = classNames.bind(styles);

type Props = {
  enrollment?: Enrollment;
};
export const FeedbackContent = ({ enrollment }: Props) => {
  const [t] = useTranslator();
  const { feedback, hasPassed } = useGetEnrollmentFeedback(enrollment);

  return (
    <section className={cx(styles.base)}>
      <div className={cx(styles.top)}>
        <div>
          <strong>{t('modals.course_feedback.rating')}</strong>
          <Status
            scheme={hasPassed ? 'green' : 'red'}
            className={cx(styles.status)}
          >
            {feedback?.ratingType !== 'numeric' &&
            feedback?.ratingType !== 'number'
              ? feedback?.rating.toString()
                ? t(`grades.${feedback?.rating}`)
                : '-'
              : feedback?.rating}
          </Status>
        </div>
        <div>
          <strong>{t('modals.course_feedback.date_of_completion')}</strong>
          <span>{DateFormatter.toDate(feedback?.createdAt) || '-'}</span>
        </div>
      </div>
      <div className={cx(styles.bottom)}>
        <strong>{t('modals.course_feedback.feedback')}</strong>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizer(feedback?.content || '-'),
          }}
        />
      </div>
    </section>
  );
};
