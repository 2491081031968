import { useCallback } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { usePlausible } from 'next-plausible';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';

type DefaultTrackingProps = {
  environment?: string;
  portal?: string;
  userRole?: string;
};

type Props = Record<string, unknown> | never;

type EventOptions<P extends Props> = {
  props: P;
  revenue?: {
    currency: string;
    amount: number;
  };
  u?: string;
  callback?: VoidFunction;
};

type Events = {
  [K: string]: Omit<Props, 'environment' | 'portal' | 'userRole'>;
};

type WithDefaultProps<P> = P extends never
  ? DefaultTrackingProps
  : P & DefaultTrackingProps;

/**
 * usePlausibleTracking
 *
 * Extends the usePlausible hook with default props which will always be passed.
 * to setup an event use the format: CONTEXT:OBJECT:ACTION
 *
 * @usage
 * const plausible = usePlausibleTracking('CONTEXT:OBJECT:ACTION', {customProp: string})
 */
export function usePlausibleTracking<E extends Events = any>() {
  const plausible = usePlausible();
  const [me] = useMe();
  const { environment } = useEnvironmentProvider();

  return useCallback(
    <N extends keyof E>(
      eventName: N,
      props?: Omit<
        EventOptions<WithDefaultProps<E[N]>>['props'],
        keyof DefaultTrackingProps
      >
    ) => {
      const defaultProps: DefaultTrackingProps = {
        environment,
        portal: me?.portal?.().name,
        userRole: me?.account?.().role,
      };

      plausible(eventName, { props: { ...defaultProps, ...props } });
    },
    [environment, me, plausible]
  );
}
