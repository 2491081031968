import { useIndexCRUD } from '../Factories/useIndexCRUD';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Student,
  StudentBulkUpdateInput,
  StudentCreateInput,
  StudentFilters,
  StudentIncludes,
  StudentSorts,
  StudentUpdateInput,
} from '../Types/Resources/Student';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IStudentProps
  extends HookParams<
    typeof StudentIncludes,
    StudentFilters,
    typeof StudentSorts
  > {
  student?: UUID;
}

export function useStudent(
  { student, ...queryParameters }: IStudentProps = {},
  settings?: IUseQueryOptions<DataDocument<Student>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { student: defaultStudent },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(student || defaultStudent),
      baseName: 'v1.students',
      baseUri: '/v1/students',
      invalidate: ['v1.accounts', 'account', 'accounts'],
      uri: `/${student || defaultStudent}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Student, StudentCreateInput, StudentUpdateInput>(
    request,
    settings
  );
}

export function useStudents(
  { ...queryParameters }: Omit<IStudentProps, 'student'> = {},
  settings?: IUseQueryOptions<DataDocument<Student[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.students',
      baseUri: '/v1/students',
      invalidate: ['v1.accounts', 'account', 'accounts'],
      queryParameters,
    },
    context
  );

  return useIndexCRUD<Student, StudentCreateInput, StudentBulkUpdateInput>(
    request,
    settings
  );
}
