import React, { useMemo } from 'react';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import classNames from 'classnames/bind';
import { EnrollmentSettingsForm } from 'Forms';
import { useTranslator } from 'Providers/Translator';
import styles from './EnrollmentSettingsModal.module.css';

const cx = classNames.bind(styles);

export const EnrollmentSettingsModal = ({
  onClose,
  course,
}: {
  onClose: () => void;
  course: Course;
}) => {
  const [t] = useTranslator();
  const enrollmentId = useMemo(() => course.enrollment?.()?.id, [course]);

  return (
    <ContentModal
      onClose={onClose}
      className={cx(styles.base)}
      style={{ overflow: 'visible' }}
    >
      <ContentModal.Header>
        <h2>{t('settings')}</h2>
      </ContentModal.Header>

      <EnrollmentSettingsForm enrollmentId={enrollmentId} />
    </ContentModal>
  );
};
