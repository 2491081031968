import React from 'react';
import { LtiResourceLink } from '@brainstud/academy-api/Types/Resources/LtiResourceLink';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './LtiModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  ltiResourceLink?: LtiResourceLink;
  closeModal: () => void;
};

/**
 * A modal to confirm you are opening external LTI content in a new tab/window.
 */
export const LaunchLtiResourceModal = ({
  ltiResourceLink,
  closeModal,
}: TProps) => {
  const [t] = useTranslator();

  return (
    <ConfirmationModal
      title={t('modals.integrations.lti.title')}
      description={t('modals.integrations.lti.open_external_course')}
      closeable
      onClose={closeModal}
      className={cx(styles.launch)}
      yes={t('modals.integrations.lti.to_course')}
      no={t('close')}
      handleConfirm={() => {
        window.open(ltiResourceLink?.links?.launchResource.href, '_blank');
        closeModal();
      }}
    >
      {!ltiResourceLink && (
        <Callout error>
          <p>{t('modals.integrations.lti.invalid')}</p>
        </Callout>
      )}
    </ConfirmationModal>
  );
};
