import React, { useCallback, useMemo } from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { SettingAssignmentSet, SettingCoach, SettingLevel } from './Partials';
import styles from './EnrollmentSettingsForm.module.css';

const cx = classNames.bind(styles);

export const EnrollmentSettingsForm = ({
  enrollmentId,
}: {
  enrollmentId?: UUID;
}) => {
  const [t] = useTranslator();
  const [{ data: enrollment, destroy }] = useEnrollment({
    enrollment: enrollmentId,
  });
  const course = useMemo(() => enrollment?.course?.(), [enrollment]);
  const { invalidateQueries } = useApi();
  const router = useRouter();
  const { openModal, closeModal } = useModals();

  const { isCoachEnvironment, isAdminEnvironment, isStudentEnvironment } =
    useEnvironmentProvider();
  const handleReroute = useCallback(() => {
    if (isCoachEnvironment) {
      router.replace('/coach');
    } else if (isAdminEnvironment) {
      router.replace('/admin');
    } else if (isStudentEnvironment) {
      router.replace('/catalog');
    }
  }, [isAdminEnvironment, isCoachEnvironment, isStudentEnvironment, router]);

  const handleUnenroll = useCallback(() => {
    openModal(
      ConfirmationModal,
      {
        title: t('unenroll'),
        yes: t('forms.enrollment_settings.confirm'),
        no: t('cancel'),
        question: t('forms.enrollment_settings.do_you_want_to_unenroll', {
          course: course?.title,
        }),
        handleConfirm: async () =>
          destroy
            .mutateAsync(undefined, {
              onSuccess: () => {
                invalidateQueries(['v1.enrollments']);
                handleReroute();
                closeModal();
              },
            })
            .then(() => {
              closeModal();
            }),
      },
      1
    );
  }, [
    openModal,
    t,
    course?.title,
    destroy,
    invalidateQueries,
    handleReroute,
    closeModal,
  ]);

  return (
    <div className={cx(styles.base)}>
      <div className={cx(styles.column)}>
        <SettingCoach enrollmentId={enrollmentId} />
        <SettingAssignmentSet enrollmentId={enrollmentId} />
        <SettingLevel enrollmentId={enrollmentId} />
      </div>
      <div>
        <Button error loading={destroy.isPending} onClick={handleUnenroll}>
          {t('unenroll')}
        </Button>
      </div>
    </div>
  );
};
