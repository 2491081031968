import { useMemo } from 'react';
import { Enrollment } from '@brainstud/academy-api/Types/Resources/Enrollment';

/**
 * useGetEnrollmentFeedback
 *
 * Calculates feedback, rating, and whether the rating passed based on enrollment and assessment data.
 */
export const useGetEnrollmentFeedback = (enrollment?: Enrollment) => {
  const examAssessment = useMemo(
    () => enrollment?.exam?.().assessment?.(),
    [enrollment]
  );
  const feedback = useMemo(
    () =>
      examAssessment
        ? {
            content: examAssessment.feedback,
            createdAt: examAssessment.date,
            ratingType: examAssessment.gradeType.toLowerCase(),
            rating:
              typeof examAssessment.gradeResult === 'string'
                ? examAssessment.gradeResult.toLowerCase()
                : examAssessment.gradeResult,
            isExam: true,
          }
        : enrollment?.feedback?.()[0],
    [enrollment, examAssessment]
  );

  const rating = useMemo(
    () =>
      typeof feedback?.rating === 'boolean'
        ? feedback?.rating.toString()
        : feedback?.rating,
    [feedback?.rating]
  );

  const hasPassed = ['v', 'g', 'true'].includes(rating) || rating >= 5.5;

  return { feedback, hasPassed };
};
