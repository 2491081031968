import React, { useCallback } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { ReactComponent as ReducedUserExperienceIllustration } from 'Assets/reduced_user_experience.svg';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './ReducedUserExperienceModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  onClose: () => void;
};

/**
 * A modal that explains there is a reduced user experience when editing courses on a non-desktop device.
 */
export const ReducedUserExperienceModal = ({ onClose }: Props) => {
  const [t] = useTranslator();

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <ContentModal className={cx(styles.base)}>
      <ContentModal.Header>
        <h1>{t('modals.reduced_user_experience_modal.title')}</h1>
      </ContentModal.Header>
      <p>{t('modals.reduced_user_experience_modal.content')}</p>
      <ReducedUserExperienceIllustration style={{ margin: '0 auto' }} />
      <ContentModal.Footer>
        <Button type="button" quiet onClick={handleCloseModal}>
          {t('modals.reduced_user_experience_modal.close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
