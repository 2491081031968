import { useOrder } from '@brainstud/academy-api/Hooks/useOrders';
import { Button } from '@brainstud/ui/Buttons/Button';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import classNames from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { useTranslator } from 'Providers/Translator';
import { OrderDetailPartialView } from 'Views/Admin/Sales/Partial/OrderDetailPartialView';
import styles from './OrderDetailModal.module.css';

const cx = classNames.bind(styles);

interface OrderDetailModalProps {
  /** The orderId for which to show the information */
  orderId: string;
  /** An optional action to perform on closing the modal */
  closeModal: () => void;
}

/**
 * OrderDetailModal.
 *
 * A modal that shows order information based on the given orderId.
 */
export const OrderDetailModal = ({
  orderId,
  closeModal,
}: OrderDetailModalProps) => {
  const [t] = useTranslator();
  const [{ data: order }, { isLoading }] = useOrder({
    order: orderId,
    include: [
      'invoices',
      'transactions',
      'prepaid_codes',
      'subject',
      'order_lines',
      'order_lines.product_add_on',
      'order_lines.prepaid_codes',
      'order_lines.prepaid_codes.product',
    ],
  });

  return (
    <SidebarModal
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {order ? (
            <OrderDetailPartialView order={order} isPartial />
          ) : (
            <Loading />
          )}

          <SidebarModal.Footer>
            <Button type="button" quiet onClick={closeModal}>
              {t('modals.close')}
            </Button>
          </SidebarModal.Footer>
        </>
      )}
    </SidebarModal>
  );
};
