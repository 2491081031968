import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Schedule,
  ScheduleCreateInput,
  ScheduleFilters,
  ScheduleIncludes,
  ScheduleSorts,
} from '../Types/Resources/Schedule';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IScheduleProps
  extends HookParams<
    typeof ScheduleIncludes,
    typeof ScheduleFilters,
    typeof ScheduleSorts
  > {
  schedule?: UUID;
}

export function useSchedule(
  { schedule, ...queryParameters }: IScheduleProps = {},
  settings?: IUseQueryOptions<DataDocument<Schedule>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { schedule: defaultSchedule },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(schedule || defaultSchedule),
      baseName: 'v1.schedules',
      baseUri: '/v1/schedules',
      uri: `/${schedule || defaultSchedule}`,
      invalidate: ['schedule', 'schedules'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    Schedule,
    ScheduleCreateInput,
    Partial<ScheduleCreateInput>
  >(request, settings);
}

export function useSchedules(
  { ...queryParameters }: IScheduleProps = {},
  settings?: IUseQueryOptions<DataDocument<Schedule[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.schedules',
      baseUri: '/v1/schedules',
      queryParameters,
    },
    context
  );
  return useIndexCR<Schedule, ScheduleCreateInput>(request, settings);
}
