import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LtiToolProvider,
  LtiToolProviderCreateInput,
  LtiToolProviderFilters,
  LtiToolProviderIncludes,
  LtiToolProviderSorts,
  LtiToolProviderUpdateInput,
} from '../Types/Resources/LtiToolProvider';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILtiToolProviderProps
  extends HookParams<
    typeof LtiToolProviderIncludes,
    typeof LtiToolProviderFilters,
    typeof LtiToolProviderSorts
  > {
  ltiToolProvider?: UUID;
}

/**
 * LtiToolProvider is a registration of an external LTI tool of which content can be used within the platform.
 * @param ltiToolProvider
 * @param queryParameters
 * @param settings
 */
export function useLtiToolProvider(
  { ltiToolProvider, ...queryParameters }: ILtiToolProviderProps = {},
  settings?: IUseQueryOptions<DataDocument<LtiToolProvider>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { ltiToolProvider: defaultLtiToolProvider },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(ltiToolProvider || defaultLtiToolProvider),
      baseName: 'v1.ltiToolProviders',
      baseUri: `/v1/lti_tool_providers`,
      uri: `/${ltiToolProvider}`,
      invalidate: ['lti_tool_providers'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LtiToolProvider,
    LtiToolProviderCreateInput,
    LtiToolProviderUpdateInput
  >(request, settings);
}

/**
 * LtiToolProvider is a registration of an external LTI tool of which content can be used within the platform
 * @param queryParameters
 * @param settings
 */
export function useLtiToolProviders(
  { ...queryParameters }: Omit<ILtiToolProviderProps, 'ltiToolProvider'> = {},
  settings?: IUseQueryOptions<DataDocument<LtiToolProvider[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.ltiToolProviders',
      baseUri: `/v1/lti_tool_providers`,
      invalidate: ['lti_tool_providers'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LtiToolProvider, LtiToolProviderCreateInput>(
    request,
    settings
  );
}
