import React from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import classNames from 'classnames/bind';
import { useGetEnrollmentFeedback } from 'Hooks/useGetEnrollmentFeedback';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as PartyPopper } from 'public/media/icons/party_popper.svg';
import { FeedbackContent } from './FeedbackContent';
import styles from './CourseFeedbackModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  closeModal: () => void;
  enrollmentId: UUID;
  course: Course;
  hasAccess?: boolean;
  handleConfirm?: () => void;
};

export const CourseFeedbackModal = ({
  closeModal,
  enrollmentId,
  course,
  hasAccess,
  handleConfirm,
}: Props) => {
  const [t] = useTranslator();

  const [{ data: enrollment }] = useEnrollment({
    enrollment: enrollmentId,
    include: ['feedback', 'exam.assessment'],
  });

  const { hasPassed } = useGetEnrollmentFeedback(enrollment);

  return (
    <ContentModal closeable onClose={closeModal}>
      <ContentModal.Header className={cx(styles.header)}>
        <h2 className={cx(styles.title)}>{course?.title}</h2>
        <div className={cx(styles.subHeader)}>
          {hasPassed && <PartyPopper />}
          <strong>
            {hasPassed && t('modals.course_feedback.success')}
            {t('modals.course_feedback.title')}
          </strong>
        </div>
      </ContentModal.Header>
      <FeedbackContent enrollment={enrollment} />
      <ContentModal.Footer>
        {hasAccess && (
          <Button onClick={handleConfirm} type="button">
            {t('modals.course_feedback.open_course')}
          </Button>
        )}
        <Button quiet onClick={() => closeModal()}>
          {t('cancel')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
