import React, {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useFormField } from '@brainstud/universal-components/Components/Form/useFormField';
import classNames from 'classnames/bind';
import { Wrap } from 'Components/Wrap';
import { useTranslator } from 'Providers/Translator';
import { RadioProps } from './RadioProps';
import styles from './RadioButtonGroup.module.css';

const cx = classNames.bind(styles);

type TProps = {
  rules?: string | Array<null | undefined | string>;
  id: string;
  onChange?: (value: string) => void;
  defaultValue?: string | null;
  label?: string;
  className?: string;
};

const Fieldset = ({
  label,
  children,
}: PropsWithChildren<{ label?: string }>) => (
  <fieldset className={cx(styles.fieldset)}>
    <legend>{label}</legend>
    {children}
  </fieldset>
);

/**
 * RadioButtonGroup.
 *
 * A Radio input wrapper that styles the Radio inputs as a vertical group of buttons.
 */
export const RadioButtonGroup = ({
  children,
  rules,
  id,
  label,
  onChange,
  defaultValue,
  className,
}: PropsWithChildren<TProps>) => {
  const { messages, setValue, value } = useFormField({ rules, id });
  const hasError = Array.isArray(messages) && messages.length > 0;
  const [t] = useTranslator();

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange?.(e.target.value);
    },
    [onChange, setValue]
  );

  const childrenWithOnChange = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement<RadioProps & any>(child, {
            onChange: handleOnChange,
            checked: value === child.props.defaultValue,
          });
        }

        return child;
      }),
    [children, handleOnChange, value]
  );

  /**
   * Check the default value once and update if needed
   */
  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
      onChange?.(defaultValue);
    }
  }, [value, defaultValue, setValue, onChange]);

  return (
    <div className={cx(styles.base, className)}>
      <Wrap if={!!label} with={Fieldset} props={{ label }}>
        <div className={cx(styles.children, { hasError })}>
          {childrenWithOnChange}
        </div>
      </Wrap>

      {hasError ? (
        <div role="alert" className={cx(styles.message)}>
          {t(messages[0])}
        </div>
      ) : null}
    </div>
  );
};
