import { QueryObserverResult, UseMutationResult } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useDestroy } from '../Factories/useDestroy';
import { useIndex } from '../Factories/useIndex';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  CoachMandate,
  CoachMandateBulkDestroyInput,
  CoachMandateCreateInput,
  CoachMandateFilters,
  CoachMandateIncludes,
  CoachMandateSorts,
  CoachMandateUpdateInput,
} from '../Types/Resources/CoachMandate';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ICoachMandateProps
  extends HookParams<
    typeof CoachMandateIncludes,
    typeof CoachMandateFilters,
    typeof CoachMandateSorts
  > {
  enrollment?: UUID;
  coachMandate?: UUID;
}

export function useCoachMandate(
  { enrollment, coachMandate, ...queryParameters }: ICoachMandateProps = {},
  settings?: IUseQueryOptions<DataDocument<CoachMandate>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      coachMandate: defaultCoachMandate,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(coachMandate || defaultCoachMandate),
      baseName: 'v1.coachMandates',
      baseUri: '/v1/coach_mandates',
      uri: `/${coachMandate}`,
      invalidate: [
        'coach_mandate',
        'coach_mandates',
        'v1.students',
        'student',
        'students',
      ],
      data: {
        enrollment: enrollment || defaultEnrollment,
      },
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    CoachMandate,
    CoachMandateCreateInput,
    CoachMandateUpdateInput
  >(request, settings);
}

type TOutput = [
  {
    data: CoachMandate[];
    create: UseMutationResult<
      DataDocument<CoachMandate>,
      ErrorDocument,
      CoachMandateCreateInput
    >;
    destroy: UseMutationResult<
      DataDocument<CoachMandate>,
      ErrorDocument,
      CoachMandateBulkDestroyInput
    >;
  },
  QueryObserverResult<DataDocument<CoachMandate[]>, ErrorDocument>,
];

export function useCoachMandates(
  { ...queryParameters }: Omit<ICoachMandateProps, 'coachMandate'> = {},
  settings?: IUseQueryOptions<DataDocument<CoachMandate[]>, ErrorDocument>
): TOutput {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.coachMandates',
      baseUri: '/v1/coach_mandates',
      invalidate: [
        'coach_mandate',
        'coach_mandates',
        'v1.students',
        'student',
        'students',
      ],
      queryParameters,
    },
    context
  );

  const [data, responseIndex] = useIndex<CoachMandate>(request, settings);

  const create = useCreate<CoachMandate, CoachMandateCreateInput>(request);

  const destroy = useDestroy<CoachMandate, CoachMandateBulkDestroyInput>(
    request
  );

  return [
    {
      data,
      create,
      destroy,
    },
    responseIndex,
  ];
}
