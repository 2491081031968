import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LtiResourceLink,
  LtiResourceLinkCreateInput,
  LtiResourceLinkFilters,
  LtiResourceLinkIncludes,
  LtiResourceLinkSorts,
  LtiResourceLinkUpdateInput,
} from '../Types/Resources/LtiResourceLink';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILtiResourceLinkProps
  extends HookParams<
    typeof LtiResourceLinkIncludes,
    typeof LtiResourceLinkFilters,
    typeof LtiResourceLinkSorts
  > {
  ltiResourceLink?: UUID;
}

/**
 * LtiResourceLink is a link used to register the integration of a tool deployment in the platform.
 */
export function useLtiResourceLink(
  { ltiResourceLink, ...queryParameters }: ILtiResourceLinkProps = {},
  settings?: IUseQueryOptions<DataDocument<LtiResourceLink>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { ltiResourceLink: defaultLtiResourceLink },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(ltiResourceLink || defaultLtiResourceLink),
      baseName: 'v1.ltiResourceLinks',
      baseUri: `/v1/lti_resource_links`,
      uri: `/${ltiResourceLink}`,
      invalidate: ['ltiResourceLinks'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LtiResourceLink,
    LtiResourceLinkCreateInput,
    LtiResourceLinkUpdateInput
  >(request, settings);
}

/**
 * LtiResourceLink is a link used to register the integration of a tool deployment in the platform
 */
export function useLtiResourceLinks(
  { ...queryParameters }: Omit<ILtiResourceLinkProps, 'ltiResourceLink'> = {},
  settings?: IUseQueryOptions<DataDocument<LtiResourceLink[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.ltiResourceLinks',
      baseUri: `/v1/lti_resource_links`,
      invalidate: ['ltiResourceLinks'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LtiResourceLink, LtiResourceLinkCreateInput>(
    request,
    settings
  );
}
