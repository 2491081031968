import React, { useCallback, useState } from 'react';
import { useEnrollmentFeedback } from '@brainstud/academy-api/Hooks/useEnrollmentFeedback';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import {
  RatingTypes,
  ratingTypesMap,
} from '@brainstud/academy-api/Types/Resources/Feedback';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { DropdownOption } from '@brainstud/ui/Form Input/Dropdown/Option';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Radio } from '@brainstud/ui/Form Input/Radio';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { RichTextEditor } from 'Components/RichTextEditor';
import { useGetEnrollmentFeedback } from 'Hooks/useGetEnrollmentFeedback';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { usePlausibleTracking } from '../../Hooks/usePlausible';
import { FeedbackContent } from '../CourseFeedbackModal/FeedbackContent';
import styles from './CourseCompletionModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  studentFullName: string;
  courseTitle: string;
  closeModal: () => void;
  isComplete: boolean;
};

type TCourseFeedbackForm = {
  rating_type: RatingTypes;
  rating: string | number;
  content: string;
};

export const CourseCompletionModal = ({
  studentFullName,
  courseTitle,
  closeModal,
  isComplete,
}: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const [ratingType, setRatingType] = useState<string>('text');
  const { enrollmentId } = router.query as {
    studentId: string;
    enrollmentId: string;
  };

  const [{ data: enrollment, update: updateEnrollment }] = useEnrollment({
    enrollment: enrollmentId,
    include: ['feedback', 'exam.assessment'],
  });
  const { feedback } = useGetEnrollmentFeedback(enrollment);

  const [{ create: createEnrollmentFeedback }] = useEnrollmentFeedback(
    {
      enrollment: enrollmentId,
    },
    { enabled: false }
  );

  const handleRevert = useCallback(async () => {
    await updateEnrollment
      .mutateAsync({
        _method: 'patch',
        is_completed: !isComplete,
      })
      .then(() => {
        closeModal();
      });
  }, [closeModal, isComplete, updateEnrollment]);

  const plausible = usePlausibleTracking();

  const addFeedback = useCallback(
    async (data: TCourseFeedbackForm) => {
      await createEnrollmentFeedback
        .mutateAsync({
          rating_type: data.rating_type,
          rating: ratingType !== 'unset' ? data.rating : '',
          content: data.content,
        })
        .then(() => {
          plausible('enrollment:modal:completed', {
            enrollment: enrollmentId,
            course: enrollment?.course?.().title,
            grade: ratingType !== 'unset' ? data.rating : '',
          });
          closeModal();
        });
    },
    [
      closeModal,
      createEnrollmentFeedback,
      enrollment,
      enrollmentId,
      plausible,
      ratingType,
    ]
  );

  return (
    <SidebarModal
      closeable
      size="medium"
      onClose={closeModal}
      style={{ overflow: 'visible' }}
    >
      <SidebarModal.Header className={cx(styles.header)}>
        <h4 className={cx(styles.title)}>
          {t(
            isComplete
              ? 'modals.course_completion.existing.title'
              : 'modals.course_completion.title'
          )}
        </h4>
        {!feedback?.isExam && (
          <>
            {isComplete ? (
              <Button
                type="button"
                onClick={handleRevert}
                loading={updateEnrollment.isPending}
              >
                {t('modals.course_completion.existing.confirm')}
              </Button>
            ) : (
              <Button
                form="feedback"
                type="submit"
                loading={
                  updateEnrollment.isPending ||
                  createEnrollmentFeedback.isPending
                }
              >
                {t('modals.course_completion.confirm')}
              </Button>
            )}
          </>
        )}
      </SidebarModal.Header>
      <section className={cx(styles.content)}>
        {isComplete ? (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t('modals.course_completion.existing.subtitle', {
                    courseTitle,
                  })
                ),
              }}
            />
            {!feedback?.isExam && (
              <Callout
                warning
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    t('modals.course_completion.existing.content', {
                      name: studentFullName,
                      courseTitle,
                    }),
                    'inlineOnly'
                  ),
                }}
              />
            )}
          </>
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                t('modals.course_completion.content', {
                  name: studentFullName,
                  courseTitle,
                }),
                'inlineOnly'
              ),
            }}
          />
        )}

        {!isComplete ? (
          <Form<TCourseFeedbackForm>
            autoReset={false}
            id="feedback"
            onSubmit={addFeedback}
            className={cx(styles.feedBack)}
          >
            <div className={cx(styles.grade)}>
              <Dropdown
                name="rating_type"
                defaultValue="text"
                label={t('modals.course_completion.select_grade')}
                block
                onChange={(key) => setRatingType(key as string)}
              >
                <Dropdown.Option key="unset" value="unset">
                  {t('modals.course_completion.grade.unset')}
                </Dropdown.Option>
                {Object.entries(ratingTypesMap).map(([key]) => (
                  <DropdownOption key={key} value={key}>
                    {t(`modals.course_completion.grade.${key}`)}
                  </DropdownOption>
                ))}
              </Dropdown>
              {(() => {
                switch (ratingType) {
                  case 'text':
                    return (
                      <>
                        <Radio
                          name="rating"
                          id="o"
                          defaultValue="o"
                          label={t('grades.o')}
                        />
                        <Radio
                          name="rating"
                          defaultChecked
                          id="v"
                          defaultValue="v"
                          label={t('grades.v')}
                        />
                        <Radio
                          name="rating"
                          id="g"
                          defaultValue="g"
                          label={t('grades.g')}
                        />
                      </>
                    );
                  case 'numeric':
                    return (
                      <Input
                        className={cx(styles.spacing)}
                        name="rating"
                        type="number"
                        placeholder="7"
                        max={10}
                        min={0}
                      />
                    );
                  case 'boolean':
                    return (
                      <div>
                        <Radio
                          name="rating"
                          id="true"
                          defaultChecked
                          defaultValue="true"
                          label={t('grades.true')}
                        />
                        <Radio
                          name="rating"
                          id="false"
                          defaultValue="false"
                          label={t('grades.false')}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })()}
            </div>
            <RichTextEditor
              label={t('review.optionalFeedback')}
              preset="minimal"
              placeholder={t('review.feedbackPlaceholder')}
              name="content"
            />
          </Form>
        ) : (
          <FeedbackContent enrollment={enrollment} />
        )}
      </section>
    </SidebarModal>
  );
};
