import React, { useCallback } from 'react';
import { useLtiResourceLink } from '@brainstud/academy-api/Hooks/useLtiResourceLinks';
import { useLtiToolProviders } from '@brainstud/academy-api/Hooks/useLtiToolProviders';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Textarea } from '@brainstud/ui/Form Input/Textarea';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import styles from './IntegrationsForm.module.css';

const cx = classNames.bind(styles);

export type TLtiResourceLinkFormData = {
  launchUrl?: string;
  loginUrl?: string;
  secret?: string;
  ltiToolProvider: string;
};

type TProps = {
  ltiResourceLinkId?: UUID;
  formId?: string;
  courseId?: string;
  onSuccess?: (ltiResourceLinkId: string) => void;
};

/**
 * A form to create or update a ltiResourceLink
 */
export const LtiResourceLinkForm = ({
  ltiResourceLinkId,
  courseId,
  formId,
  onSuccess,
}: TProps) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [{ data: ltiToolProviders }] = useLtiToolProviders();
  const [{ data: ltiResourceLink, createOrUpdate }, { isLoading }] =
    useLtiResourceLink(
      {
        ltiResourceLink: ltiResourceLinkId,
      },
      { enabled: !!ltiResourceLinkId }
    );

  const action = ltiResourceLinkId ? 'update' : 'create';

  const handleSubmit = useCallback(
    (formData: TLtiResourceLinkFormData) => {
      createOrUpdate.mutateAsync(
        {
          ...formData,
          relationships: {
            ltiToolProvider: formData.ltiToolProvider,
            course: courseId,
          },
        },
        {
          onSuccess: (response) => {
            setToast(
              t(`forms.integrations.lti.${action}.lti_resource_link.success`),
              'success'
            );
            onSuccess?.(response.data.id);
          },
          onError: () => {
            setToast(
              t(`forms.integrations.lti.${action}.lti_resource_link.error`),
              'error'
            );
          },
        }
      );
    },
    [action, courseId, createOrUpdate, onSuccess, setToast, t]
  );

  return (
    <div className={cx(styles.base)}>
      {ltiResourceLinkId && isLoading ? (
        <Loading />
      ) : (
        <Form<TLtiResourceLinkFormData>
          onSubmit={handleSubmit}
          id={formId}
          className={cx(styles.form)}
          autoReset={false}
          disableAfterSubmit={false}
        >
          <Dropdown
            label={t('forms.integrations.lti.lti_tool_provider')}
            placeholder={t('forms.integrations.lti.select_lti_tool_provider')}
            value={ltiResourceLink?.ltiToolProvider?.().id}
            name="ltiToolProvider"
            rules="required"
          >
            {ltiToolProviders.map((ltiToolProvider) => (
              <Dropdown.Option
                key={ltiToolProvider.id}
                value={ltiToolProvider.id}
              >
                {ltiToolProvider.title}
              </Dropdown.Option>
            ))}
          </Dropdown>
          <Input
            label={`${t('forms.integrations.lti.launch_url')} (${t('forms.integrations.lti.default_from_provider')} )`}
            name="launchUrl"
            defaultValue={ltiResourceLink?.launchUrl}
            placeholder={ltiResourceLink?.ltiToolProvider().launchUrl}
          />
          <Input
            label={`${t('forms.integrations.lti.login_url')} (${t('forms.integrations.lti.default_from_provider')} )`}
            name="loginUrl"
            defaultValue={ltiResourceLink?.loginUrl}
            placeholder={ltiResourceLink?.ltiToolProvider().loginUrl}
          />
          <Textarea
            label={`${t('forms.integrations.lti.secret')} (${t('forms.integrations.lti.default_from_provider')} )`}
            name="secret"
            defaultValue={ltiResourceLink?.secret}
            placeholder={ltiResourceLink?.ltiToolProvider().privateKey}
          />
          <div>
            <Button loading={createOrUpdate.isPending} type="submit">
              {t('save')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
