import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  OrderAutomationProduct,
  OrderAutomationProductCreate,
  OrderAutomationProductFilters,
  OrderAutomationProductIncludes,
  OrderAutomationProductSort,
  OrderAutomationProductUpdate,
} from '../Types/Resources/OrderAutomationProduct';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IOrderAutomationProductsProps
  extends HookParams<
    typeof OrderAutomationProductIncludes,
    OrderAutomationProductFilters,
    typeof OrderAutomationProductSort
  > {
  orderAutomation?: UUID;
  orderAutomationProduct?: UUID;
}

export function useOrderAutomationProduct(
  {
    orderAutomationProduct,
    orderAutomation,
    ...queryParameters
  }: IOrderAutomationProductsProps = {},
  settings?: IUseQueryOptions<
    DataDocument<OrderAutomationProduct>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(orderAutomationProduct || defaultOrder),
      baseName: 'v1.order_automation.order_automation_product',
      baseUri: `/v1/order_automations/${orderAutomation}/order_automation_products`,
      invalidate: ['order_automation_products'],
      uri: `/${orderAutomationProduct}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    OrderAutomationProduct,
    OrderAutomationProductCreate,
    OrderAutomationProductUpdate
  >(request, settings);
}
